import React from 'react';

import { convertUnits } from 'shared/components/formatters/unit-conversion.formatter';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';
import { getUnit } from 'shared/components/formatters/unit-label.formatter';
import { MeasurementType } from 'shared/enums/measurement-type.enum';
import { useStoredUser } from '@app/hooks/store/use-stored-user.hook';

interface Props {
  value: number;
  from?: UnitOfMeasure;
  float?: number;
  withoutUnits?: boolean;
  type: MeasurementType;
}

export const Conversion: React.FunctionComponent<Props> = (props) => {
  const targetUnitOfMeasure = useStoredUser()?.getUnitOfMeasure();
  const { value, type, from = UnitOfMeasure.US, withoutUnits, float = 0 } = props;
  const convertedValue = convertUnits(value, type, targetUnitOfMeasure, from) || ((value == null || value as any === '') ? '' : 0);
  return (
    <span className="conversion">
      {Number(convertedValue).toLocaleString(undefined, { minimumFractionDigits: float, maximumFractionDigits: float })}
      {withoutUnits ? '' : ` ${getUnit(targetUnitOfMeasure, type)}`}
    </span>
  );
};

