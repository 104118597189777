import React from 'react';

import { TranslationJSX } from 'shared/components/translation/translation.component';
import { getUnit } from 'shared/components/formatters/unit-label.formatter';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';
import { MeasurementType } from 'shared/enums/measurement-type.enum';
import { Temperature } from 'shared/models/my-loads/measurements/temperature.model';
import { useStoredUser } from '@app/hooks/store/use-stored-user.hook';

export interface TempProps {
  maxTemp?: Temperature;
  minTemp?: Temperature;
  type?: MeasurementType;
  precisionPointsNumber? : number;
}

/**
 * Returns measure depending unit configured by the user
 * @param measure
 * @param unitOfMeasure
 * @param type
 * @param precisionPointsNumber
 * @returns
 */
const getMeasureText = (
  measure: Temperature,
  unitOfMeasure: UnitOfMeasure,
  type: MeasurementType,
  precisionPointsNumber?: number
) => {
  const isMetric = unitOfMeasure === UnitOfMeasure.Metric;
  const valueOfMeasure = isMetric ? measure?.metric : measure?.standard;

  if (!valueOfMeasure && isNaN(valueOfMeasure)) {
    return '-';
  }

  const unit = getUnit(unitOfMeasure, type);

  if(typeof precisionPointsNumber === 'undefined' && !precisionPointsNumber ){
    return `${valueOfMeasure}${unit}`;
  }

  return `${valueOfMeasure?.toFixed(precisionPointsNumber)}${unit}`;
};

/**
 * Component that shows temperature details if the equipment code is Reefer, otherwise return dash
 */
export const TemperatureComponent: React.FC<TempProps> = ({ maxTemp, minTemp, type, precisionPointsNumber }) => {
  const targetUnitOfMeasure = useStoredUser()?.getUnitOfMeasure() ?? UnitOfMeasure.Standard;
  return (
    <span className="temperature">
      <TranslationJSX
        resource="TEMPERATURE_DETAILS"
        replacements={[
          () => <span>{getMeasureText(minTemp, targetUnitOfMeasure, type, precisionPointsNumber)}</span>,
          () => <span>{getMeasureText(maxTemp, targetUnitOfMeasure, type, precisionPointsNumber)}</span>,
          () => <small>(Continuous)</small>,
        ]}
      />
    </span>
  );
};
