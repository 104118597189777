import React from 'react';

import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';
import { MeasurementType } from 'shared/enums/measurement-type.enum';
import { Conversion } from 'shared/components/formatters/conversion/conversion.component';

import { TemperatureComponent, TempProps } from 'pages/find-loads/details-equipment-temperature.formatter';
import { convertUnits } from '../unit-conversion.formatter';

export interface ConversionProps {
  value: number;
  float?: number;
  from?: UnitOfMeasure;
  withoutUnits?: boolean;
}

export const Distance: React.FunctionComponent<ConversionProps> = (props) =>
  <Conversion type={MeasurementType.Distance} {...props} />;

export const Weight: React.FunctionComponent<ConversionProps> = (props) =>
  <Conversion type={MeasurementType.Weight} {...props} />;

export const Length: React.FunctionComponent<ConversionProps> = (props) =>
  <Conversion type={MeasurementType.Length} {...props} />;

export const SmallLength: React.FunctionComponent<ConversionProps> = (props) =>
  <Conversion type={MeasurementType.SmallLength} {...props} />;

export const Volume: React.FunctionComponent<ConversionProps> = (props) =>
  <Conversion type={MeasurementType.Volume} {...props} />;

export const SmallVolume: React.FunctionComponent<ConversionProps> = (props) =>
  <Conversion type={MeasurementType.Volume} {...props} />;

export const Temperature: React.FunctionComponent<TempProps> = (props) =>
  <TemperatureComponent type={MeasurementType.Temperature} {...props} />;

const convertType = (type: MeasurementType) =>
  (value: number, targetFormat: UnitOfMeasure, sourceFormat: UnitOfMeasure = UnitOfMeasure.US) =>
    convertUnits(value, type, targetFormat, sourceFormat);

export const convertWeight = convertType(MeasurementType.Weight);
export const convertDistance = convertType(MeasurementType.Distance);
